import React, { useState, useEffect, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from '@fortawesome/free-solid-svg-icons';
import "./TablePurchase.css"
import Loader from "../Loader";
import ModulesModal from "../ModulesModal";
import SubscriptionsModule from "../SubscriptionsModule";
import ComboModal from "../ComboModal";

const TablePurchase = ({
    title,
    rows = [],
    columns = [],
    renderRow,
    icon,
    onRowClick,
    selectedRow,
    onRefresh,
    loading,
    selectedModules,
    setSelectedModules,
    onMergeRows,
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSubscriptionModalOpen, setSubscriptionIsModalOpen] = useState(false);
    const [isComboModalOpen, setComboIsModalOpen] = useState(false);
    // Usamos useCallback para memorizar mergeDataWithSelectedModules
    const mergeDataWithSelectedModules = useCallback(() => {
        const updatedData = [...rows];
        selectedModules.forEach(selected => {
            const index = updatedData.findIndex(item => item.moduleId === selected.moduleId);
            if (index !== -1) {
                updatedData[index] = selected;
            } else {
                updatedData.push(selected);
            }
        });

        return updatedData;
    }, [rows, selectedModules]); // Añadimos rows y selectedModules como dependencias

    const allRows = useMemo(() => mergeDataWithSelectedModules(), [mergeDataWithSelectedModules]);

    useEffect(() => {
        if (onMergeRows) {
            onMergeRows(allRows);
        }
    }, [allRows, onMergeRows]);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeSubscriptionModal = () => {
        setSubscriptionIsModalOpen(false);
    };
    const openSubscriptionModal = () => {
        setSubscriptionIsModalOpen(true);
    };

    const closeComboModal = () => {
        setComboIsModalOpen(false);
    };
    const openComboModal = () => {
        setComboIsModalOpen(true);
    };


    const handleAddModules = (newModules) => {
        setSelectedModules((prevModules) => {
            // Combinar los módulos previos con los nuevos, asegurándote de no duplicar moduleId
            const updatedModules = [...prevModules];
            newModules.forEach((newModule) => {
                const exists = updatedModules.some(module => module.moduleId === newModule.moduleId);
                if (!exists) {
                    updatedModules.push(newModule);
                }
            });
            return updatedModules;
        });
        closeModal();
        closeSubscriptionModal();
    };


    return (
        <div className="sales-table-container2">
            <div className="sales-table-header2">
                <div className="header-left2">
                    <div className="icon-container-table">
                        <FontAwesomeIcon icon={icon} className="icon" />
                    </div>
                    <FontAwesomeIcon
                        icon={faSync}
                        className="shortcut-icon-actually"
                        onClick={onRefresh}
                        style={{ cursor: 'pointer' }}
                    />
                    <h3>{title}</h3>
                </div>
                <div className="header-right">
                    <button className="basic-table-button" onClick={openModal}>
                        <span className="button-line">Módulos</span>
                        <span className="button-line"> EXTRAS</span>
                    </button>
                    <button className="basic-table-button" onClick={openSubscriptionModal}>
                        <span className="button-line">Suscripciones</span>
                    </button>
                    <button className="basic-table-button" onClick={openComboModal}>
                        <span className="button-line">Combos</span>
                    </button>
                </div>

            </div>
            <table className="sales-table">
                <thead>
                    <tr>
                        {columns.map((col, index) => (
                            <th key={index}>{col.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <tr>
                            <td colSpan={columns.length} className="loader-container">
                                <Loader />
                            </td>
                        </tr>
                    ) : allRows.length > 0 ? (
                        allRows.map((item, index) => (
                            <tr
                                key={item.id || index}
                                onClick={() => onRowClick && onRowClick(item)}
                                className={item === selectedRow ? "selected-row" : ""}
                            >
                                {renderRow(item, index)}
                                {columns.map((col) => col.render && <td key={col.key}>{col.render(item)}</td>)}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={columns.length} className="no-data">No existen datos</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {isModalOpen && (
                <ModulesModal
                    isVisible={isModalOpen}
                    onClose={() => closeModal()}
                    onAddModules={handleAddModules}
                    initialSelectedModules={selectedModules}
                />
            )}
            {isSubscriptionModalOpen && (
                <SubscriptionsModule
                    isVisible={isSubscriptionModalOpen}
                    onClose={() => closeSubscriptionModal()}
                    onAddModules={handleAddModules}
                    initialSelectedModules={selectedModules}
                />
            )}
            {isComboModalOpen && (
                <ComboModal
                    isVisible={isComboModalOpen}
                    onClose={() => closeComboModal()}
                    onAddModules={handleAddModules}
                    initialSelectedModules={selectedModules}
                />
            )}

        </div>
    );
};

export default TablePurchase;
