import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import './ResetPassword.css';
import apiClient from "../../axios";
import SuccessProcess from '../../components/Notifications/SuccessProcess';
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
    faSave,
    faXmark,
    faEyeSlash,
    faEye
} from "@fortawesome/free-solid-svg-icons";
import imgFenix from '../../../src/static/ACL-Color.png';

const ResetPassword = ({ handleLogout }) => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmarClave, setConfirmarClave] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [successVisible, setSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({});
    const [errors, setErrors] = useState({});
    const [message2, setMessage2] = useState('');


    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleShowPassword2 = () => {
        setShowPassword2((prevShowPassword) => !prevShowPassword);
    };


    const validateFields = () => {
        let validationErrors = {};
        if (!password) validationErrors.password = "El Campo es Obligatorio";
        if (password !== confirmarClave) {
            validationErrors.confirmarClave = "Las claves no coinciden";
        }
        return validationErrors;
    };

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {

            const payload = {
                password
            };
            const response = await apiClient.post(`/request_password_reset/${token}`, payload);
            if (response.data.statusCode === 200) {
                setSuccessVisible(true);
                setMessage2('La contraseña se a restablecido correctamente.');
            }

        } catch (error) {
            if (error.response.data.message.startsWith("Token expired")) {
                setMessage2('El token temporal para recuperar tu contraseña ha expirado. Por favor, solicita un nuevo enlace para restablecer tu contraseña.');
                setSuccessVisible(true);
            }
            setErrorMessage("Oops!. Ocurrio un error al restablecer la Contraseña");
            setIsErrorVisible(true);
            setTimeout(() => {
                setIsErrorVisible(false);
            }, 4000);

        }
    };

    const handleCloseSuccess = () => {
        setSuccessVisible(false);
        handleLogout();
    };

    return (
        <div className="login-container ">
            <div className="moduleNew-form-container2">
                <div className="logo-fenix">
                    <img src={imgFenix} alt="Logo" />
                </div>
                <h3 className="basic-info-form-title">Restablecer Contraseña</h3>
                <div className="basic-info-form-grid1">
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.password ? "red" : "inherit" }}>
                            Nueva Contraseña
                        </label>
                        <div className="password-input-container">
                            <input
                                className="text-box-login2"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Contraseña"
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className="password-toggle-icon2"
                                onClick={toggleShowPassword}
                            />
                        </div>
                        {errors.password && <p className="error-message">{errors.password}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.confirmarClave ? "red" : "inherit" }}>
                            Confirmar Contraseña
                        </label>
                        <div className="password-input-container">
                            <input
                                className="text-box-login2"
                                type={showPassword2 ? "text" : "password"}
                                placeholder="Confirmar Clave"
                                value={confirmarClave}
                                onChange={(e) => setConfirmarClave(e.target.value)}
                            />
                            <FontAwesomeIcon
                                icon={showPassword2 ? faEyeSlash : faEye}
                                className="password-toggle-icon2"
                                onClick={toggleShowPassword2}
                            />
                        </div>
                        {errors.confirmarClave && <p className="error-message">{errors.confirmarClave}</p>}
                    </div>
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate("/Home");
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button
                        className="basic-custom-button"
                        onClick={handleSave}
                    >
                        <FontAwesomeIcon
                            icon={faSave}
                            className="basic-shortcut-icon"
                        />
                        Guardar
                    </button>
                </div>
            </div>
            <ErrorNotification
                message={errorsMessage}
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <SuccessProcess
                enca="Información"
                message={message2}
                isVisible={successVisible}
                onClose={handleCloseSuccess}
                redirectOnOk={true}
                pageNavigate={'/Home'}
            />
        </div>
    );
};

export default ResetPassword;
