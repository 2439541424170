import React, { useState, useEffect } from "react";
import apiClient from "../../axios";
import SuccessProcess from '../Notifications/SuccessProcess';
import LoginNotification from "../Notifications/LoginNotification";
import ErrorNotification from '../Notifications/ErrorNotification';
import Loader from "../Loader";

const ContractIdentifiersModal = ({ isVisible, onClose, contract }) => {
    const [successVisible, setSuccessVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [numSerie, setNumSerie] = useState(null);
    const [servidor, setServidor] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (contract) {
            const fetchAllData = async () => {
                try {
                    setLoading(true);
                    const response = await apiClient.get(`/contracts/${contract.numCont}?skipLogin=true`);
                    if (response.status === 200) {
                        setNumSerie(response.data.numSerie);
                        setServidor(response.data.servidor);
                    }
                } catch (error) {
                    setIsErrorVisible(true);
                } finally {
                    setLoading(false);
                }

            };
            fetchAllData();
        }

    }, [contract]);


    const validateFields = () => {
        let validationErrors = {};
        if (!servidor) validationErrors.servidor = "El Campo es Obligatorio";
        if (!numSerie) validationErrors.numSerie = "El Campo es Obligatorio";
        return validationErrors;
    };

    const handleConfirm = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            setLoading(true);
            const payload = {
                numSerie,
                servidor
            };
            const response = await apiClient.patch(`/contracts/${contract.numCont}/identifiers`, payload);
            if (response.status === 200) {
                setSuccessVisible(true);
            }

        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }

        } finally {
            setLoading(false);
        }

    }

    const handleCloseSuccess = () => {
        setSuccessVisible(false);
        onClose();
    };

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info2">
                    <div className="message-container-info">
                        <h3 className="basic-info-form-title">Actualización de datos</h3>
                    </div>
                    <div className="message-container-info">
                        <div className="basic-info-form-group">
                            <label style={{ color: errors.numSerie ? "red" : "inherit" }}>Nro. Identificador</label>
                            <input
                                type="text"
                                placeholder="Nro. Identificador"
                                value={numSerie}
                                onChange={(e) => setNumSerie(e.target.value)}
                            />
                            {errors.numSerie && (
                                <p style={{ fontSize: '12px', color: 'red' }}>
                                    {errors.numSerie}
                                </p>
                            )}
                        </div>
                        <div className="basic-info-form-group">
                            <label style={{ color: errors.servidor ? "red" : "inherit" }}>Servidor</label>
                            <input
                                type="text"
                                name="Servidor"
                                value={servidor}
                                onChange={(e) => setServidor(e.target.value)}

                            />
                            {errors.servidor && (
                                <p style={{ fontSize: '12px', color: 'red' }}>
                                    {errors.servidor}
                                </p>
                            )}

                        </div>

                    </div>
                    <div className="button-container-info">
                        <button className="cancel-btn-info" onClick={onClose}>
                            Cancelar
                        </button>
                        <button className="ok-btn-info" onClick={handleConfirm}>
                            OK
                        </button>
                    </div>
                    {loading && <Loader />}
                    <SuccessProcess
                        message={"Se actualizo el contarto correctamente."}
                        isVisible={successVisible}
                        onClose={handleCloseSuccess}
                        redirectOnOk={false}
                    />
                    <ErrorNotification
                        message="Ups! Ocurrio un Problema"
                        isVisible={isErrorVisible}
                        onClose={() => setIsErrorVisible(false)}
                    />
                    <LoginNotification
                        message="Vuele a iniciar sesión"
                        isVisible={isInfoVisible}
                        onClose={() => setIsInfoVisible(false)}
                    />
                </div>
            </div>
        )
    );
};

export default ContractIdentifiersModal;
