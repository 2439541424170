import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import './DistributorsForm.css';
import Header from "../../components/Header";
import Section from "../../components/Section";
import SuccessNotification from "../../components/Notifications/SuccessNotification";
import ErrorNotification from "../../components/Notifications/ErrorNotification";
import LoginNotification from "../../components/Notifications/LoginNotification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import {
    faSave,
    faRotate,
    faCircleArrowLeft,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import apiClient from "../../axios";

const DistributorsForm = ({ handleLogout }) => {
    const navigate = useNavigate();
    const { codeDistributor } = useParams();
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [client, setClient] = useState("");
    const [clientSearch, setClientSearch] = useState("");
    const [purchaseBlock, setPurchaseBlock] = useState(0);
    const [blockReason, setBlockReason] = useState("");
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [errors, setErrors] = useState({});
    const [searchTerm, setSearchTerm] = useState(''); // Para el valor de búsqueda
    const [data, setData] = useState([]);

    const toggleSwitch = () => {
        setPurchaseBlock((prev) => !prev);
    };

    const validateFields = () => {
        let validationErrors = {};
        const onlyLetters = /^[a-zA-Z]+$/;
        if (!code) {
            validationErrors.code = "El Campo es Obligatorio";
        } else if (!onlyLetters.test(code)) {
            validationErrors.code = "Solo se permiten letras";
        }
        if (!name) validationErrors.name = "El Campo es Obligatorio";
        if (!client) validationErrors.client = "El Campo es Obligatorio";
        return validationErrors;
    };


    const handleClick = () => {
        navigate(-1);
    };

    const handleSearch = useCallback(async (page = 1) => {
        try {
            const params = new URLSearchParams();
            if (searchTerm) params.append('search', searchTerm);

            const response = await apiClient.get(`/clients?${params.toString()}&page=${page}`);

            if (Array.isArray(response.data.results)) {
                setData(response.data.results);
            } else {
                setData([response.data.results]);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    }, [searchTerm]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchTerm) {
                handleSearch();
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, handleSearch]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setClientSearch(value);
        setSearchTerm(value);

        if (value.trim() === "") {
            setData([]);
        }
    };

    const handleClientSelect = (code, name) => {
        setClient(code);
        setClientSearch(name);
        setSearchTerm('');
        setData([]);
    };

    useEffect(() => {
        if (codeDistributor) {
            const fetchDistributor = async () => {
                try {
                    const response = await apiClient.get(`/distributors/${codeDistributor}`);
                    setCode(response.data.coddis);
                    setName(response.data.nomdis);
                    setClient(response.data.codcli);
                    setPurchaseBlock(response.data.purchaseBlock);
                    setBlockReason(response.data.blockReason);
                    const codcli = response.data.codcli;
                    try {
                        const customer = await apiClient.get(`/clients/${codcli}`);
                        setClientSearch(customer.data.nomcli);
                    }
                    catch (error) {
                        console.error("Error al cargar el Distribuidor", error);
                        if (error.response && error.response.status === 401) {
                            setIsInfoVisible(true);
                        }
                    }

                } catch (error) {
                    console.error("Error al cargar el Distribuidor", error);
                    if (error.response && error.response.status === 401) {
                        setIsInfoVisible(true);
                    }
                }
            };
            fetchDistributor();
        }
    }, [codeDistributor]);

    const handleSave = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            if (codeDistributor) {
                const payloadUpdate = {
                    name,
                    client,
                    purchaseBlock,
                    blockReason
                };
                await apiClient.patch(`/distributors/${codeDistributor}`, payloadUpdate);
            } else {
                const payload = {
                    code,
                    name,
                    client
                };
                await apiClient.post("/distributors", payload);
            }
            setIsSuccessVisible(true);
            setIsErrorVisible(false);
            navigate(-1);
        } catch (error) {
            setIsErrorVisible(true);
            setIsSuccessVisible(false);
            setTimeout(() => {
                setIsErrorVisible(false);
            }, 4000);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    };

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title="Distribuidor" />
            <Section>
                <div className="button-return-container">
                    <FontAwesomeIcon
                        className="basic-shortcut-icon"
                        style={{ cursor: "pointer" }}
                        icon={faCircleArrowLeft}
                        onClick={handleClick}
                    />
                </div>
            </Section>
            <div className="moduleNew-form-container">
                <h3 className="basic-info-form-title">Información del Distribuidor</h3>
                <div className="basic-info-form-grid">
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.code ? "red" : "inherit" }}>Código</label>
                        <input
                            type="text"
                            placeholder="Código"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            readOnly={!!codeDistributor}
                        />
                        {errors.code && <p className="error-message">{errors.code}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.name ? "red" : "inherit" }}>Nombre</label>
                        <input
                            type="text"
                            placeholder="Nombre"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && <p className="error-message">{errors.name}</p>}
                    </div>
                    <div className="basic-info-form-group">
                        <label>Cliente</label>
                        <input
                            type="text"
                            placeholder="Cliente"
                            value={clientSearch}
                            onChange={handleInputChange}
                        />
                    </div>
                    {data.length > 0 && (
                        <div className="search-results">
                            <ul>
                                {data.map((item) => (
                                    <li key={item.id} onClick={() => handleClientSelect(item.code, item.socialReason)}>
                                        {item.socialReason}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <div className="basic-info-form-group">
                        <label style={{ color: errors.client ? "red" : "inherit" }}>Documento de Identificación</label>
                        <input
                            type="text"
                            placeholder="Documento de Identificación"
                            value={client}
                            onChange={(e) => setClient(e.target.value)}
                        />
                        {errors.client && <p className="error-message">{errors.client}</p>}
                    </div>

                </div>
                <div className="basic-info-form-grid">
                    {codeDistributor && (
                        <div className="basic-info-form-group4">
                            <label>Estado</label>
                            <div className="slider-container" onClick={toggleSwitch}>
                                <div className={`slider-option ${!purchaseBlock ? "active" : "inactive"}`}>
                                    Activo
                                </div>
                                <div className={`slider-option ${purchaseBlock ? "active" : "inactive"}`}>
                                    Inactivo
                                </div>
                            </div>
                        </div>
                    )}
                    {codeDistributor && purchaseBlock ? (
                        <div className="basic-info-form-group">
                            <label>Motivo</label>
                            <input
                                type="text"
                                placeholder="Motivo"
                                value={blockReason}
                                onChange={(e) => setBlockReason(e.target.value)}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
                <div className="basic-form-footer">
                    <button
                        className="basic-custom-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(-1);
                        }}
                    >
                        <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                        Cancelar
                    </button>
                    <button className="basic-custom-button" onClick={handleSave}>
                        <FontAwesomeIcon
                            icon={codeDistributor ? faRotate : faSave}
                            className="basic-shortcut-icon"
                        />
                        {codeDistributor ? "Actualizar" : "Guardar"}
                    </button>
                </div>
            </div>
            <SuccessNotification
                message={
                    codeDistributor
                        ? "El Distribuidor se ha Actualizado Correctamente."
                        : "El Distribuidor se ha Creado Correctamente."
                }
                isVisible={isSuccessVisible}
                onClose={() => setIsSuccessVisible(false)}
            />
            <ErrorNotification
                message="Hubo un problema al Crear el Distribuidor. Inténtalo de nuevo."
                isVisible={isErrorVisible}
                onClose={() => setIsErrorVisible(false)}
            />
            <LoginNotification
                message="Vuele a iniciar sesión"
                isVisible={isInfoVisible}
                onClose={() => setIsInfoVisible(false)}
                onLogout={handleLogout}
            />
        </div>
    );
};

export default DistributorsForm;
