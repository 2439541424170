import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck,faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import './SuccessProcess.css';

const SuccessProcess = ({ enca, message, isVisible, onClose, redirectOnOk, pageNavigate }) => {

    const navigate = useNavigate(); 
    const [title, setTitle] = useState('Guardado Correctamente');

    const handleOkClick = () => {
        if (redirectOnOk) {
            navigate(pageNavigate); 
        } else {
            onClose(); 
        }
    };

    useEffect(() => {
        if (enca) {
            setTitle(enca);
        }
    }, [enca]);

    return (
        isVisible && (
            <div className="info-notification-info-overlay">
                <div className="info-notification-info">
                    <div className="icon-container-noti-info">
                        <FontAwesomeIcon  icon={enca ? faCircleInfo : faCircleCheck} className={enca ? "icon-noti4" : "icon-noti3"}/>
                    </div>
                    <div className="message-container-info">
                        <h4>{title}</h4>
                        <p>{message}</p>
                    </div>
                    <div className="button-container-info">
                        <button className="ok-btn-info" onClick={handleOkClick }>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        )
    );
};

export default SuccessProcess;
