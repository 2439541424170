import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileSignature, faUserTie } from "@fortawesome/free-solid-svg-icons";
import LoginNotification from "../../components/Notifications/LoginNotification";

const CustomerManagement = ({ handleLogout }) => {
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const navigate = useNavigate();

    const hasPermission = (permission) => {
        const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
        return permissions.includes(permission);
    };

    const goToContractReassignment = () => navigate("/ContractReassignment");
    const goToDistributorReassignment = () => navigate("/DistributorReassignment");

    return (
        <div className="home-container">
            <Header title="Gestión de Clientes" onLogout={handleLogout} />
            <div className="home_modules-container">
                {hasPermission('CONTRACT_REASSIGN') && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#12509B" }}
                        onClick={goToContractReassignment}
                    >
                        <FontAwesomeIcon icon={faFileSignature} size="2x" />
                        <p>Asignación de Contrato a nuevo Cliente</p>
                    </div>
                )}
                {hasPermission('DISTRIBUTOR_REASSIGN') && (
                    <div
                        className="module_item-container2"
                        style={{ backgroundColor: "#104588" }}
                        onClick={goToDistributorReassignment}
                    >
                        <FontAwesomeIcon icon={faUserTie} size="2x" />
                        <p>Cambio de Distribuidor</p>
                    </div>
                )}
                <LoginNotification
                    message="Vuele a iniciar sesión"
                    isVisible={isInfoVisible}
                    onClose={() => setIsInfoVisible(false)}
                    onLogout={handleLogout}
                />
            </div>
        </div >
    );
};

export default CustomerManagement;
