import React, { useState, useEffect } from 'react';
import TableModule from '../../components/TableModule';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faXmark, faCheck,  faSearch } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';
import apiClient from "../../axios";

const PermissionsModal = ({ message, isVisible, onClose, rol }) => {
    const [data, setData] = useState([]);
    const [selectedModules, setSelectedModules] = useState([]);
    const [rolPermissions, setRolPermissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const itemsPerPage = 10;
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (rol.id) {
            const fetchRol = async () => {
                try {
                    const response = await apiClient.get(`/admin/roles/${rol.id}`);
                    const roleModules = response.data.permissions.map(permission => permission.code);
                    setSelectedModules(roleModules);
                    setRolPermissions(roleModules);
                } catch (error) {
                    console.error("Error al cargar el rol", error);
                }
            };
            fetchRol();
        }
    }, [rol.id]);

    const fetchAllData = useCallback(async (page = 1) => {
        try {
            setLoading(true);
            const response = await apiClient.get(`/admin/permissions?page=${page}`);
            let fetchedData = [];
            if (Array.isArray(response.data.results)) {
                fetchedData = response.data.results;
            } else {
                fetchedData = [response.data.results];
            }

            // Reordenar los datos para que los seleccionados aparezcan primero
            const reorderedData = fetchedData.sort((a, b) => {
                const aSelected = selectedModules.includes(a.code);
                const bSelected = selectedModules.includes(b.code);
                return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
            });

            setData(reorderedData);
            setTotalItems(response.data.total);
        } catch (error) {
            setIsErrorVisible(true);
        } finally {
            setIsSuccessVisible(true);
            setLoading(false);
        }
    }, [selectedModules]);

    useEffect(() => {
        fetchAllData(currentPage);
    }, [currentPage, fetchAllData]);

    const handleRefresh = () => {
        fetchAllData(currentPage);
    };

    const handleSave = async () => {
        const toAssign = selectedModules.filter(code => !rolPermissions.includes(code));
        const toRevoke = rolPermissions.filter(code => !selectedModules.includes(code));
        
        const payload = {
            toAssign,
            toRevoke
        };

        try {
            await apiClient.post(`/admin/roles/${rol.id}/permissions`, payload);
            setIsSuccessVisible(true);
        } catch (error) {
            console.error("Error al guardar los permisos", error);
            setIsErrorVisible(true);
        } finally {
            onClose();
        }
    };

    const handleCheckboxChange = (code) => {
        setSelectedModules(prevSelected =>
            prevSelected.includes(code)
                ? prevSelected.filter(selectedCode => selectedCode !== code)
                : [...prevSelected, code]
        );
    };

    const handleSelectAll = () => {
        if (selectedModules.length === data.length) {
            setSelectedModules([]);
        } else {
            setSelectedModules(data.map(item => item.code));
        }
    };

    
    const handleSearch = useCallback(async (page = 1) => {
        try {
            // Construir la URL con los query params
            const params = new URLSearchParams();
            if (search) params.append('search', search);

            const response = await apiClient.get(`/admin/permissions?${params.toString()}&page=${page}`);

            if (Array.isArray(response.data.results)) {
                setData(response.data.results); // Si es un array, lo dejamos tal cual
            } else {
                setData([response.data.results]); // Si es un objeto, lo encapsulamos en un array
            }
            setTotalItems(response.data.total);
        } catch (error) {
            setIsErrorVisible(true);
        }
    }, [search]);
    const handleFresh = async (code) => {
        handleSearch(1)
    };

    const columns = [
        { title: "Id", key: "id" },
        { title: "Código", key: "code" },
        { title: "Descripción", key: "name" },
        { title: "Seleccionar", key: "select" },
        
    ];

    const renderRow = (item, index) => (
        <>
            <td>{item.id}</td>
            <td>{item.code}</td>
            <td>{item.description}</td>
            <td >
                <input
                    type="checkbox"
                    checked={selectedModules.includes(item.code)}
                    onChange={() => handleCheckboxChange(item.code)}
                />
            </td>
        </>
    );

    return (
        isVisible && (
            <div className="modal-overlay">
                <div className="modal-content-module">
                    <div className="modal-content-module-body">
                        <h3 className="modal-title ">Permisos</h3>
                        <div className="filter-form">
                            <div className="basic-info-form-group">
                                <input
                                    type="text"
                                    id="customer"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Permiso"
                                />
                            </div>
                            <button className="search-button-customer" onClick={handleFresh}>
                                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                                Buscar
                            </button>

                        </div>
                        <TableModule
                            title="Lista de Permisos"
                            rows={data}
                            columns={columns}
                            icon={faClipboardCheck}
                            renderRow={renderRow}
                            currentPage={currentPage}
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            onPageChange={setCurrentPage}
                            onRefresh={handleRefresh}
                            onSelectAll={handleSelectAll}
                            isAllSelected={selectedModules.length === data.length}
                            loading={loading}
                            select={true}
                        />

                    </div>
                    <div className="basic-form-footer">
                        <button
                            className="basic-custom-button"
                            onClick={onClose}
                        >
                            <FontAwesomeIcon icon={faXmark} className="basic-shortcut-icon" />
                            Cancelar
                        </button>
                        <button className="basic-custom-button" onClick={handleSave}>
                            <FontAwesomeIcon icon={faCheck} className="basic-shortcut-icon" />
                            Aceptar
                        </button>
                    </div>
                </div>
                <SuccessNotification
                    message={"Se ha cargado correctamente"}
                    isVisible={isSuccessVisible}
                    onClose={() => setIsSuccessVisible(false)}
                />
                <ErrorNotification
                    message="Ups! Ocurrio un Problema"
                    isVisible={isErrorVisible}
                    onClose={() => setIsErrorVisible(false)}
                />
            </div>

        )

    );
};

export default PermissionsModal;
